body {
  margin: 0;
 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ql-align-center{
  text-align: center;
}

.ql-container{
  height: 600px !important;
}

/** Styles Google maps autocomplete **/
.pac-container{
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 

}
.pac-item{
  padding: 4px;
}
.pac-container.pac-logo{
  z-index: 5500;
}

.pac-logo::after{
  margin: 2px 10px 10px 0;
}